import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import { registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import initI18n from "i18n";
import App from "App";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ENV_CONFIG from "env";
import "./styles/index.scss";
import 'mapbox-gl/dist/mapbox-gl.css';
import "components/commons/MapboxNavigationPanel/index.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
if (ENV_CONFIG.SENTRY) {
    Sentry.init({
        dsn: ENV_CONFIG.SENTRY.DSN,
        environment: ENV_CONFIG.SENTRY.ENVIRONMENT,
        release: ENV_CONFIG.SENTRY.RELEASE,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.replayIntegration({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
    });
}
document.title = ENV_CONFIG.DOCUMENT_TITLE;
// Set webpage favicon
const element = document.createElement("link");
element.rel = "icon";
element.href = ENV_CONFIG.FAVICON_LINK;
document.getElementsByTagName("head")[0].appendChild(element);
initI18n();
// Register Filepond plugins to be used within the app
registerPlugin(FilePondPluginFileValidateType);
const root = createRoot(document.getElementById("root"));
root.render(React.createElement(StrictMode, null,
    React.createElement(App, null)));
if (module.hot) {
    module.hot.accept();
}
